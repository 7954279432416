"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildDelegateAction = exports.DelegateAction = void 0;
const types_1 = require("@near-js/types");
const action_creators_1 = require("./action_creators");
const { addKey, createAccount, deleteAccount, deleteKey, deployContract, functionCall, stake, transfer, } = action_creators_1.actionCreators;
class DelegateAction extends types_1.Assignable {
}
exports.DelegateAction = DelegateAction;
/**
 * Compose a delegate action for inclusion with a meta transaction signed on the sender's behalf
 * @params.actions The set of actions to be included in the meta transaction
 * @params.maxBlockHeight The maximum block height for which this action can be executed as part of a transaction
 * @params.nonce Current nonce on the access key used to sign the delegate action
 * @params.publicKey Public key for the access key used to sign the delegate action
 * @params.receiverId Account ID for the intended receiver of the meta transaction
 * @params.senderId Account ID for the intended signer of the delegate action
 */
function buildDelegateAction({ actions, maxBlockHeight, nonce, publicKey, receiverId, senderId, }) {
    return new DelegateAction({
        senderId,
        receiverId,
        actions: actions.map((a) => {
            // @ts-expect-error type workaround
            if (!a.type && !a.params) {
                return a;
            }
            // @ts-expect-error type workaround
            switch (a.type) {
                case 'AddKey': {
                    // @ts-expect-error type workaround
                    const { publicKey, accessKey } = a.params;
                    return addKey(publicKey, accessKey);
                }
                case 'CreateAccount': {
                    // @ts-expect-error type workaround
                    return createAccount(a.params.createAccount);
                }
                case 'DeleteAccount': {
                    // @ts-expect-error type workaround
                    return deleteAccount(a.params.deleteAccount);
                }
                case 'DeleteKey': {
                    // @ts-expect-error type workaround
                    return deleteKey(a.params.publicKey);
                }
                case 'DeployContract': {
                    // @ts-expect-error type workaround
                    return deployContract(a.params.code);
                }
                case 'FunctionCall': {
                    // @ts-expect-error type workaround
                    const { methodName, args, gas, deposit } = a.params;
                    return functionCall(methodName, args, gas, deposit);
                }
                case 'Stake': {
                    // @ts-expect-error type workaround
                    return stake(a.params.stake, a.params.publicKey);
                }
                case 'Transfer': {
                    // @ts-expect-error type workaround
                    const { deposit } = a.params;
                    return transfer(deposit);
                }
            }
            throw new Error('Unrecognized action');
        }),
        nonce,
        maxBlockHeight,
        publicKey,
    });
}
exports.buildDelegateAction = buildDelegateAction;
