"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SCHEMA = exports.SignedTransaction = exports.Transaction = exports.decodeSignedTransaction = exports.decodeTransaction = exports.encodeTransaction = exports.encodeSignedDelegate = exports.encodeDelegateAction = void 0;
const crypto_1 = require("@near-js/crypto");
const types_1 = require("@near-js/types");
const borsh_1 = require("borsh");
const actions_1 = require("./actions");
const delegate_1 = require("./delegate");
const prefix_1 = require("./prefix");
const signature_1 = require("./signature");
/**
 * Borsh-encode a delegate action for inclusion as an action within a meta transaction
 * NB per NEP-461 this requires a Borsh-serialized prefix specific to delegate actions, ensuring
 *  signed delegate actions may never be identical to signed transactions with the same fields
 * @param delegateAction Delegate action to be signed by the meta transaction sender
 */
function encodeDelegateAction(delegateAction) {
    return new Uint8Array([
        ...(0, borsh_1.serialize)(exports.SCHEMA, new prefix_1.DelegateActionPrefix()),
        ...(0, borsh_1.serialize)(exports.SCHEMA, delegateAction),
    ]);
}
exports.encodeDelegateAction = encodeDelegateAction;
/**
 * Borsh-encode a signed delegate for validation and execution by a relayer
 * @param signedDelegate Signed delegate to be executed in a meta transaction
 */
function encodeSignedDelegate(signedDelegate) {
    return (0, borsh_1.serialize)(exports.SCHEMA, signedDelegate);
}
exports.encodeSignedDelegate = encodeSignedDelegate;
function encodeTransaction(transaction) {
    return (0, borsh_1.serialize)(exports.SCHEMA, transaction);
}
exports.encodeTransaction = encodeTransaction;
/**
 * Borsh-decode a Transaction instance from a buffer
 * @param bytes Buffer data to be decoded
 */
function decodeTransaction(bytes) {
    return (0, borsh_1.deserialize)(exports.SCHEMA, Transaction, bytes);
}
exports.decodeTransaction = decodeTransaction;
/**
 * Borsh-decode a SignedTransaction instance from a buffer
 * @param bytes Buffer data to be decoded
 */
function decodeSignedTransaction(bytes) {
    return (0, borsh_1.deserialize)(exports.SCHEMA, SignedTransaction, bytes);
}
exports.decodeSignedTransaction = decodeSignedTransaction;
class Transaction extends types_1.Assignable {
    encode() {
        return encodeTransaction(this);
    }
    static decode(bytes) {
        return decodeTransaction(bytes);
    }
}
exports.Transaction = Transaction;
class SignedTransaction extends types_1.Assignable {
    encode() {
        return encodeTransaction(this);
    }
    static decode(bytes) {
        return decodeSignedTransaction(bytes);
    }
}
exports.SignedTransaction = SignedTransaction;
exports.SCHEMA = new Map([
    [signature_1.Signature, { kind: 'struct', fields: [
                ['keyType', 'u8'],
                ['data', [64]]
            ] }],
    [SignedTransaction, { kind: 'struct', fields: [
                ['transaction', Transaction],
                ['signature', signature_1.Signature]
            ] }],
    [Transaction, { kind: 'struct', fields: [
                ['signerId', 'string'],
                ['publicKey', crypto_1.PublicKey],
                ['nonce', 'u64'],
                ['receiverId', 'string'],
                ['blockHash', [32]],
                ['actions', [actions_1.Action]]
            ] }],
    [crypto_1.PublicKey, { kind: 'struct', fields: [
                ['keyType', 'u8'],
                ['data', [32]]
            ] }],
    [actions_1.AccessKey, { kind: 'struct', fields: [
                ['nonce', 'u64'],
                ['permission', actions_1.AccessKeyPermission],
            ] }],
    [actions_1.AccessKeyPermission, { kind: 'enum', field: 'enum', values: [
                ['functionCall', actions_1.FunctionCallPermission],
                ['fullAccess', actions_1.FullAccessPermission],
            ] }],
    [actions_1.FunctionCallPermission, { kind: 'struct', fields: [
                ['allowance', { kind: 'option', type: 'u128' }],
                ['receiverId', 'string'],
                ['methodNames', ['string']],
            ] }],
    [actions_1.FullAccessPermission, { kind: 'struct', fields: [] }],
    [actions_1.Action, { kind: 'enum', field: 'enum', values: [
                ['createAccount', actions_1.CreateAccount],
                ['deployContract', actions_1.DeployContract],
                ['functionCall', actions_1.FunctionCall],
                ['transfer', actions_1.Transfer],
                ['stake', actions_1.Stake],
                ['addKey', actions_1.AddKey],
                ['deleteKey', actions_1.DeleteKey],
                ['deleteAccount', actions_1.DeleteAccount],
                ['signedDelegate', actions_1.SignedDelegate],
            ] }],
    [actions_1.CreateAccount, { kind: 'struct', fields: [] }],
    [actions_1.DeployContract, { kind: 'struct', fields: [
                ['code', ['u8']]
            ] }],
    [actions_1.FunctionCall, { kind: 'struct', fields: [
                ['methodName', 'string'],
                ['args', ['u8']],
                ['gas', 'u64'],
                ['deposit', 'u128']
            ] }],
    [actions_1.Transfer, { kind: 'struct', fields: [
                ['deposit', 'u128']
            ] }],
    [actions_1.Stake, { kind: 'struct', fields: [
                ['stake', 'u128'],
                ['publicKey', crypto_1.PublicKey]
            ] }],
    [actions_1.AddKey, { kind: 'struct', fields: [
                ['publicKey', crypto_1.PublicKey],
                ['accessKey', actions_1.AccessKey]
            ] }],
    [actions_1.DeleteKey, { kind: 'struct', fields: [
                ['publicKey', crypto_1.PublicKey]
            ] }],
    [actions_1.DeleteAccount, { kind: 'struct', fields: [
                ['beneficiaryId', 'string']
            ] }],
    [delegate_1.DelegateAction, { kind: 'struct', fields: [
                ['senderId', 'string'],
                ['receiverId', 'string'],
                ['actions', [actions_1.Action]],
                ['nonce', 'u64'],
                ['maxBlockHeight', 'u64'],
                ['publicKey', crypto_1.PublicKey],
            ] }],
    [prefix_1.DelegateActionPrefix, { kind: 'struct', fields: [
                ['prefix', 'u32'],
            ] }],
    [actions_1.SignedDelegate, { kind: 'struct', fields: [
                ['delegateAction', delegate_1.DelegateAction],
                ['signature', signature_1.Signature],
            ] }],
]);
